import React from "react";
import Link from "gatsby-link";
import Layout from "../components/Layout";

export default class IndexPage extends React.Component {
  render() {
    return (
      <Layout hideNavBar>
        <div className="IndexPage">
          <Link className="has-text-primary" to="/work">
            <div className="bd-index-fullscreen hero is-fullheight is-light">
              <div className="hero-body jcc">
                <div className="bd-index-header">
                  <div className="welcome">WELCOME</div>
                  <div className="logoWrapper">
                    <img
                      className="logo"
                      src="img/mauco-sosa/logo-mauco-sosa.svg"
                      alt="MAUCO SOSA"
                    />
                  </div>
                  <div className="giftWrapper">
                    <img
                      src="img/mauco-sosa/icono-regalo.svg"
                      alt=""
                      className="gift tada animatedOnHover infinite"
                    />
                    <div className="textHomeFooter">FOUND THIS ICON.</div>
                    <div className="textHomeFooter">IT'S A GIFT FOR YOU ;)</div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </Layout>
    );
  }
}

IndexPage.propTypes = {};
